$(document).ready(function() {
  'use strict';

  if ($('.g-recaptcha').length > 0) {
    loadCaptcha();
  }
});

function contactFormCaptcha() {
  $('#contact-form [type="submit"]').prop('disabled', false);
}

function expiredContactFormCaptcha() {
  $('#contact-form [type="submit"]').prop('disabled', true);
}

function resetCaptcha() {
  grecaptcha.reset();
  expiredContactFormCaptcha();
}

function loadCaptcha() {
  $.ajax({
    method: 'GET',
    url: 'https://www.google.com/recaptcha/api.js',
    dataType: 'script'
  });
}